<template>
    <settings-wrapper>
        <template v-slot:child>
            <archives-header />
            <div class="w-90 center mt4">
                <div class="pa3 scrollmenu">
                    <table v-cloak v-if="archives.length" class="table radius8" cellspacing="0">
                        <thead>
                            <tr style="background-color: #F4F7FF;">
                                <th class="tl w-10 borderTop">Number</th>
                                <th class="tl borderTop">Date</th>
                                <th class="tl borderTop">Type</th>
                                <th class="tl borderTop">Customer</th>
                                <th class="tl borderTop">Description</th>
                                <th class="w-10 borderTop">Point</th>
                                <th class="tl borderTop">Amount</th>
                                <th class="tl borderTop">Deleted At</th>
                                <th class="tl borderTop">Deleted By</th>
                                <!-- <th class="w-10">Action</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(data, index) in archives" :key="index">
                                <td class="borderTop">{{ data.data?.transaction.reference }}</td>
                                <td class="borderTop">{{ formatDate(data.data?.transaction.date) }}</td>
                                <td class="items-center flex borderTop">
                                    <img src="../../../assets/images/file.svg" class="mr1 icon-default mb1" alt="" />
                                    <span class="ttc pa0">{{
                                        data.data?.transaction.type === 'outflows' || data.data?.transaction.type === 'inflows' || data.data?.transaction.basedOn
                                            ? data.data?.transaction.subtype
                                                .split('_')
                                                .join(' ')
                                                .toString()
                                            : data.data.type === 'settlement'
                                            ? 'Upfront payment'
                                            : data.data.type === 'prepaid_expense'
                                            ? 'Prepaid Expense'
                                            : data.data?.transaction.type
                                    }}</span>
                                </td>
                                <td class="borderTop">
                                    <template
                                        v-if="
                                            !data.data?.transaction.entityName?.includes('Clientes Varios') &&
                                                !data.data?.transaction.entityName?.includes('Unknown')
                                        "
                                    >
                                        <router-link
                                            :to="{ name: 'Entity', params: { id: data.data?.transaction.entity } }"
                                            v-if="data.data?.transaction.entity && (role === 'owner' || role === 'admin')"
                                        >
                                            <span v-if="data.data?.transaction.entityName?.includes('undefined')">
                                                {{ data.data?.transaction.entityName?.replace('undefined', '') }}
                                            </span>
                                            <span v-else> {{ data.data?.transaction.entityName || '-' }}</span>
                                        </router-link>

                                        <span v-else>
                                            {{ data.data?.transaction.entityName || '-' }}
                                        </span>
                                    </template>

                                    <template v-else>
                                        <span
                                            v-if="
                                                data.data?.transaction.entityName === 'Clientes Varios' || data.data?.transaction.entityName === 'Unknown'
                                            "
                                            >-</span
                                        >
                                        <template v-else>
                                            <span v-if="data.data?.transaction.entityName?.includes('undefined')">
                                                {{ data.data?.transaction.entityName?.replace('undefined', '') }}
                                            </span>
                                            <span v-else>{{ data.data?.transaction.entityName || '-' }}</span>
                                        </template>

                                    </template>
                                </td>
                                <td class="borderTop w-70-l w-100" style="white-space: pre-wrap" v-if="data.type === 'TRANSACTION'">
                                    <span class="tl"
                                        v-if="data.data?.transaction.items?.length > 1 || data.data?.transaction.products?.length > 1"
                                    >
                                        {{ data.data?.transaction.items?.[0]?.productName || data.data?.transaction.items?.[0]?.name }} +{{
                                            data.data?.transaction.items.length - 1
                                        }}
                                        more...
                                    </span>
                                    <span class="tl ttc" v-else-if="data.data?.transaction.items?.[0]?.productName">
                                        {{ data.data?.transaction.items?.[0]?.productName || data.data?.transaction.description || '-' }}
                                    </span>
                                    <span class="tl ttc" v-else>
                                        {{ data.data?.transaction.items?.[0]?.name || data.data?.transaction.description || '-' }}
                                    </span>
                                </td>
                                <td class="tc borderTop">
                                    <span v-if="data.data?.transaction.transactionPoint === 'POS'" class="badge badge-pill-2 badge-primary b">P</span>
                                    <span
                                        v-else-if="
                                            data.data?.transaction.transactionPoint === 'invoice' ||
                                                (data.data?.transaction.items?.[0]?.name && data.data?.transaction.type === 'sales')
                                        " class="badge badge-pill badge-warning b">I</span>
                                        <span v-else class="badge badge-pill-2 badge-success b">D</span>
                                </td>
                                <td class="borderTop">{{ formatAmount(data.data?.transaction.price || data.data?.transaction.amount || data.data?.transaction.secondaryAmount) }}</td>
                                <td class="tl borderTop">{{ formatDate(data.data?.transaction?.createdAt) }}</td>
                                <td class="tl borderTop">{{ data.data?.transaction?.staffName }}</td>
                                <!-- <td>
                                    <a href="" @click.prevent="restoreFromArchive(data._id)">Restore</a>
                                </td> -->
                            </tr>
                            
                        </tbody>
                    </table>

                    <TableEmptyState v-else :data-array="archives" />
                </div>
            </div>
        </template>
    </settings-wrapper>
</template>

<script>
import { computed, onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import { formatAmount, formatDate } from '@/utils/lib'
import SettingsWrapper from '@/views/Settings/widgets/SettingsWrapper'
import ArchivesHeader from '@/views/Settings/widgets/ArchivesHeader'
import TableEmptyState from '@/components/TableEmptyState'

export default {
    name: 'AccountSettings',
    components: { ArchivesHeader, SettingsWrapper, TableEmptyState },

    setup() {
        const store = useStore()
        const organization = computed(() => store.state?.Settings?.organization)
        const archives = computed(() => store.state?.Settings?.archives)

        const initialState = () => ({
            stockLevel: 1
        })
        const state = reactive(initialState())

        const restoreFromArchive = (id) => {
            store.dispatch('Settings/restoreFromArchive', { id })
        }

        onMounted(() => {
            store.dispatch('Settings/getArchives')
        })

        return {
            state,
            organization,
            archives,
            formatAmount,
            formatDate,
            restoreFromArchive
        }
    }
}
</script>

<style scoped>
#button-update {
    position: relative;
    padding: 15px 50px;
    margin: 20px;
    /* display: flex; */
    /* justify-content: flex-end; */
}
.borderTop{
    border-top: none !important;
    border-bottom: none !important;
}
</style>